.form-container {
  width: 50%;
  min-height: 800px;
}

.main-container-purchase-order-add {
  overflow-y: scroll;
  height: 100vh;
  width: 100% !important;
}

.purchase-order-create-button-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.purchase-order-create-button {
  font-size: 12px !important;
  padding: 4px 10px !important;
}

.single-row-po-container {
  width: 100%;
  border: 1px solid grey;
}

.po-file-upload-container-main {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px;
  font-size: 12px !important;
}

.dropdown-toggle {
  background-color: #4caf50; /* Example: Green */
  color: white; /* Optional: Text color */
}

.dropdown-toggle:hover {
  background-color: #45a049; /* Optional: Hover effect */
}
