.color-red {
  color: red !important;
  font-weight: bold !important;
}

.background-red {
  background-color: rgb(250, 227, 227) !important;
}

.color-green {
  color: rgb(10, 199, 10) !important;
  font-weight: bold !important;
}

.background-green {
  background-color: rgb(229, 245, 229) !important;
}

.color-pink {
  color: rgb(199, 10, 193) !important;
  font-weight: bold !important;
}

.create-pack-task-container {
  padding-left: 30px;
}

.pack-task-buttons-container {
  margin-right: 4px;
}

.pack-tasks-button-container-main {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
}

.incoming-purchase-order-pack-task-details-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 100%;
  min-width: 400px;
}

.pack-task-id-link {
  color: blue !important;
  cursor: pointer;
  font-size: 12px !important;
}

.pack-task-id-link:hover {
  color: red !important;
}

.pack-task-heading {
  font-size: 16px !important;
}

.purchase-order-pack-task-details-container {
  /* background: #f8f9f9; */
  text-align: left;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.loading-page-download {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 252, 252, 0.9); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--eCraft-text-color-primary-dark);
  font-weight: bold;
  z-index: 110;
}

.pack-task-template-download-message {
  font-size: 12px;
}

.purchase-order-pack-task-details-container-main {
  padding-bottom: 200px;
}

.error-row > td {
  background-color: rgb(241, 200, 200) !important;
}

.success-row > td {
  background-color: rgb(220, 241, 200) !important;
}

.part-row > td {
  background-color: rgb(200, 208, 241) !important;
}

.consignment-heading > td {
  background-color: rgb(255, 255, 255) !important;
}

.incoming-purchase-order-merged-pack-task-details-table-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 100%;
  min-width: 400px;
}

/* Hover styles for tabs in the custom-tabs class */
.custom-tabs .nav-link:hover {
  background-color: #f0f8ff; /* Desired hover background color */
  color: #0056b3; /* Desired text color */
  border-color: #ddd; /* Optional: Customize the border color */
}

/* Active tab styling */
.custom-tabs .nav-link.active {
  background-color: #e9ecef; /* Background color for active tab */
  color: #495057; /* Text color for active tab */
  border-color: #ddd; /* Border color for active tab */
}

/* Optional: Add transition for a smooth hover effect */
.custom-tabs .nav-link {
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.incoming-purchase-order-po-view-section-summary {
  display: flex;
  flex-direction: column;

  font-size: 10px;
  width: 50%;
  min-width: 400px;
}

.incoming-purchase-order-po-view-section-summary table {
  text-align: center;
  vertical-align: middle;
}

.incoming-purchase-order-pack-task-details-table-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 80%;
  min-width: 400px;
}

.incoming-purchase-order-pack-task-summary-table-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 80%;
  min-width: 400px;
}

.incoming-purchase-order-pack-task-summary-table-container-child {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 33%;
  box-sizing: border-box;
}

.incoming-purchase-order-pack-task-summary-table-container-child-66 {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 66%;
  box-sizing: border-box;
}

.centered {
  text-align: center;
}

.centered-heading {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: grey !important;
}

.dispatch-offcanvas {
  z-index: 1061 !important;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity */
  z-index: 1060 !important; /* Ensure it's below the Offcanvas */
}
/* .offcanvas-backdrop {
  z-index: 1060 !important;
} */

.overlay-consignment-details-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 400px;
}

.consignment-details-container {
  width: 100% !important;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 400px;
}

.dispatch-offcanvas-title {
  font-size: 14px;
}

.transporter-picker-select {
  padding: 8px;
  margin-top: 8px;
}

.dispatch-details-container label {
  font-size: 10px !important;
  display: flex;
  flex-direction: column;
}

.dispatch-details-container select {
  font-size: 10px !important;
}

.dispatch-details-container input {
  font-size: 10px !important;
  width: 100%;
  padding: 4px;
}

.invoice-details-container select {
  font-size: 10px !important;
}

.invoice-number input {
  font-size: 10px !important;
  width: 100% !important;
  padding: 4px;
}

/* .invoice-input {
  font-size: 10px !important;
  width: 100% !important;
  padding: 4px !important;
} */

.checkbox-label {
  width: 100%;
}

.select-container select {
  width: 100%;
}

.select-container {
  width: 100%;
  box-sizing: border-box !important;
}

.selected-transporter-info {
  font-size: 10px;
  margin-top: 4px;
}

.headerRow th {
  color: white !important;
  font-weight: 600 !important;
  background-color: rgb(84, 157, 245) !important;
}

.invoice-section-expanded {
  text-align: left !important;
  padding: 15px;
}

.incoming-purchase-order-invoice-details-table-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 100%;
  min-width: 400px;
}

.invoice-details-table th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4 !important; /* Ensure the header has a background */

  z-index: 10; /* Ensures the header stays above other rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
}

.consignments-data-container {
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 100vh;
  padding-bottom: 300px;
}

.all-consignments-table th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4 !important; /* Ensure the header has a background */

  z-index: 10; /* Ensures the header stays above other rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
}

.all-consignments-table table {
  text-align: center;
  vertical-align: middle;
}

.invoice-number {
  display: flex;
  align-items: center; /* Vertically align items */
  gap: 5px;
  width: 100%;
}

.financial-year-text {
  border: 1px solid #ccc; /* Optional: style the input border */
  border-radius: 4px; /* Optional: rounded corners */
  font-size: 10px;
  padding: 4px;
  padding-right: 12px;
}

.invoice-input {
  flex: 1; /* Optional: allow the input to grow and take available space */
  padding: 0.5rem; /* Optional: add padding for better appearance */
  border: 1px solid #ccc; /* Optional: style the input border */
  border-radius: 4px; /* Optional: rounded corners */
}

.checkbox-container-invoice {
  display: flex !important;
  flex-direction: row !important;
}

.checkbox-container-invoice > .checkbox-label {
  font-size: 10px !important;
}

.download-packing-list-button-main {
  font-size: 12px !important;
  background-color: #f4f4f4 !important;
  color: black !important;
}

.upload-invoice-container {
  font-size: 12px;
}

.upload-invoice-container > input {
  /* : 12px !important; */
}

.remove-picklist-items-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: end;
  justify-content: end;
}

.remove-picklist-items-button {
  width: 200px !important;
  background-color: #f4f4f4 !important;
  color: black !important;
  border: 1px solid blue;
}

.remove-picklist-items-button:hover {
  width: 200px !important;
  background-color: rgb(241, 176, 176) !important;
  color: black !important;
}

.all-consignments-container {
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 300px;
}

.buttons-container-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.buttons-container-sub {
  display: flex;
  flex-direction: row;
}

.load-more-consignments-button {
  width: 100px !important;
  font-size: 10px !important;
  padding: 2px !important;
}

.bottom-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.consignments-count-container {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 12px;
}
