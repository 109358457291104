.purchase-orders-container {
  background-color: #f8f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.purchase-orders-table-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  height: 100vh;
  padding-bottom: 100px;
}

.purchase-orders-table-container.no-item {
  justify-content: center;
  align-items: center;
}

.purchase-orders-search-bar-container {
  float: right;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}

/* .purchase-orders-search-bar-row:hover{
    border: 2px solid blue;
    cursor: pointer;
  } */

.load-more-purchase-orders-button-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.load-more-purchase-orders-button {
  font-size: 12px !important;
}

.expiry-date {
  font-weight: bold;
}
.expiry-date.green {
  color: green;
}
.expiry-date.red {
  color: red;
}
.expiry-date.orange {
  color: orange;
}

.colors-overview-container {
  display: flex;
  flex-direction: row;
}

.po-id-link a {
  color: blue !important;
  text-decoration: underline !important;
}

.vendor-purchase-orders-table th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4 !important; /* Ensure the header has a background */

  z-index: 10; /* Ensures the header stays above other rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
  text-align: center;
  vertical-align: middle !important;
}

.vendor-purchase-orders-table table {
  text-align: center;
  vertical-align: middle;
}

.vendor-purchase-orders-table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.vendor-purchase-orders-table-container {
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 100vh;
  padding-bottom: 300px;
  overflow-y: scroll;
  padding-left: 30px;
  padding-right: 30px;
}
