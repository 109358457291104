.PageContainer {
  background-color: #f8f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.listings-search-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listings-search-bar-container-child {
  margin: 14px 0px;
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
}

.all-listings-container {
  overflow-y: scroll;
  background-color: #f8f9f9;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding-bottom: 100px;
  align-items: center;
}

.next-button {
  margin-top: 25px;
  margin-right: 25px;
  float: right;
}

.navbar-listings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #4a3434; */
  /* background-color: #1741b7; */
  background-color: white;
  color: #fff;
  padding: 0px !important;
  border: 1px solid #ccc;
}

/* Navbar.css */
.navbar-vendors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #4a3434; */
  /* background-color: #1741b7; */
  background-color: #f4f4f4;
  /* color: #fff; */
  color: black;
  padding: 0px !important;
  border-bottom: 1px solid #ccc;
}

.navbar-menu {
  list-style: none;
  display: flex;
}

.navbar-item {
  margin-right: 20px;
  cursor: pointer;
  font-size: 12px;
  padding: 4px;
  color: black;
  font-weight: bold;
}

.navbar-item:hover {
  background-color: #66655f;
}

.navbar-item:last-child {
  margin-right: 0;
}

ul {
  margin-bottom: 0px !important;
}

.navbar-item.active {
  color: #ff9800; /* Change the color for active item */
  font-weight: bold; /* Optionally, you can make the active item bold */
}

.listings-count-container {
  padding: 15px;
  font-size: 12px;
  font-weight: bold;
}
