.all-labels-master-data-table th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4 !important; /* Ensure the header has a background */

  z-index: 10; /* Ensures the header stays above other rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
  text-align: center;
  vertical-align: middle !important;
  font-size: 10px;
}

.all-labels-master-data-table table {
  text-align: center;
  vertical-align: middle;
}

.all-labels-master-data-table td {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 10px;
}

.labels-master-data-container {
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 100vh;
  padding-bottom: 300px;
}

.labels-master-data-table-container {
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 100vh;
  padding-bottom: 300px;
  overflow-y: scroll;
  padding-left: 30px;
  padding-right: 30px;
}

.labels-master-data-count-container {
  font-size: 12px;

  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 15px;
}

.data-table,
.data-table th,
.data-table td {
  font-size: 10px !important;
}

.nothing-found-container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
