.catalogues-container {
  background-color: #f8f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.catalogues-table-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  height: 100vh;
  padding-bottom: 100px;
}

.catalogues-search-bar-container {
  float: right;
  margin: 10px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.catalogues-search-bar-row:hover {
  /* border: 2px solid blue; */
  /* cursor: pointer; */
}

.load-more-catalogues-button-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.load-more-catalogues-button {
  font-size: 12px !important;
}

.incoming-po-text-orange {
  color: orange !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-green {
  color: rgb(10, 199, 10) !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-purple {
  color: purple !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-red {
  color: red !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.purchase-orders-table-container {
  font-size: 10px !important;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 100px;
  box-sizing: border-box; /* Ensures padding is included in the width */
  overflow-x: auto; /* Adds horizontal scroll if table overflows */
  overflow-y: auto; /* Adds vertical scroll */
  max-height: 100vh; /* Adjust the height for scrolling */
}

.purchase-orders-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.purchase-orders-table-container th {
  position: relative !important;
  top: 0;
  background-color: #f4f4f4 !important; /* Ensure the header has a background */

  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
}

.purchase-orders-table-container > table th,
.purchase-orders-table-container > table td {
  text-align: center;
  vertical-align: middle; /* For vertical centering */
}

input[type="text"] {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px !important;
  flex: 1;
  height: 30px;
}

input[type="checkbox"] {
  margin-right: 0px;
}

.filter-option:hover {
  cursor: pointer;
}

.filter-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  padding-top: 4px;
  max-height: 400px;
  width: 300px;
  z-index: 15;
  font-weight: 400;
}

.filter-dropdown-right {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  padding-top: 4px;
  max-height: 400px;
  width: 300px;
  z-index: 15;
  font-weight: 400;
  right: 0;
}

.filter-list-main {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.filter-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.filter-item:hover {
  background-color: #f0f0f0;
}

.empty-space {
  height: 40px;
}

.filter-buttons-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
}

.filters-button {
  font-size: 9px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  margin: 2px;
}
