.sidebar {
  min-width: 200px;
  max-width: 200px;

  /* background-color: #3f3e3e; */
  /* background-color: #4a3434; */
  /* background-color: #112765; */
  /* background-color: var(--eCraft-text-color-primary-dark); */
  background-color: #3f4041;
  /* background: linear-gradient(0deg, #b2ccees, #fff 100%); */

  flex: 0.3;
  height: 100vh;
  border-right: 0.5px solid rgb(216, 211, 211);
  /* box-shadow: 0 2px 5px 1px rgb(64 60 67 / 19%); */

  overflow: auto;
  vertical-align: top;
}
/* 
  .sidebar > hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--eCraft-color-grey);
  } */
.underline {
  height: 0.5px;
  margin-right: 10px;
  background-color: rgb(216, 211, 211);
}
.underline_active {
  height: 2px;
  margin-right: 10px;
  background-color: skyblue;
}
.log_out {
  display: flex;
  padding: 10px;
  font-size: 14px;
  padding-left: 10px;
  background-color: f1f3f6;
  color: var(--eCraft-text-color-primary-dark);
  align-items: center;
  cursor: pointer;
}
.log_out:hover {
  background-color: var(--eCraft-color-grey_hover);
}
.log_out_icon {
  margin-right: 10px;
  /* margin-top: 1px; */
  /* margin-bottom: 10px; */
  font-size: 18px !important;
}
/* .log_out > h3 {
    font-weight: 500; */
/* } */
.log_out_name {
  font-weight: 500;
  padding-bottom: 3px;
  font-size: 14px;
}
