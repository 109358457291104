.po-documents-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 300px;
}

.po-document-section-heading {
  font-size: 14px;
}

.po-document-section-no-document {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid lightgrey;
  align-items: center;
}

.incoming-purchase-order-file-details-table-container {
  display: flex;
  flex-direction: column;

  font-size: 10px;
  width: 100%;
  min-width: 400px;
}

.incoming-purchase-order-file-details-table-container table {
  text-align: center;
  vertical-align: middle;
}

.missing-boxes-message {
  font-size: 12px;
  color: red;
}

.expandable-content-invoice {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-out;
  text-align: left;
}
.expandable-content-invoice td {
}

.expanded-container-sub {
  display: flex;
  width: 100%;
}

.actions-container {
  flex: 0 0 30%; /* Take up 30% of the width */
  max-width: 30%; /* Ensure it doesn’t grow beyond 30% */
}

.downloadables-container {
  flex: 1; /* Take the remaining 70% */
}

.container-invoice-upload-section {
  display: flex;
  flex-direction: column;
}
